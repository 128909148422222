// Generated by Framer (b6cf623)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["r3GmlGDnb", "PNXJSb_ER"];

const serializationHash = "framer-Nt3nr"

const variantClassNames = {PNXJSb_ER: "framer-v-q3exq2", r3GmlGDnb: "framer-v-sjdfbs"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "r3GmlGDnb", Phone: "PNXJSb_ER"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, PiOn2dwG9: image ?? props.PiOn2dwG9 ?? {alt: "", src: "https://framerusercontent.com/images/2e9rGrOkACVfd78cX0SzqLLw.svg"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "r3GmlGDnb"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string; alt?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PiOn2dwG9, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "r3GmlGDnb", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-sjdfbs", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"r3GmlGDnb"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-90ab9b9d-c64e-4230-9e06-707b75634f37, rgb(255, 255, 255))", borderBottomLeftRadius: 14, borderBottomRightRadius: 14, borderTopLeftRadius: 14, borderTopRightRadius: 14, ...style}} {...addPropertyOverrides({PNXJSb_ER: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 180) - 0) - 42) / 2)))), pixelHeight: 42, pixelWidth: 104, sizes: "104px", ...toResponsiveImage(PiOn2dwG9), ...{ positionX: "center", positionY: "center" }}} className={"framer-1mce2ck"} layoutDependency={layoutDependency} layoutId={"DT3u3elqt"} {...addPropertyOverrides({PNXJSb_ER: {background: {alt: "", fit: "fit", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 180) - 0) - 30) / 2)))), pixelHeight: 42, pixelWidth: 104, sizes: "74px", ...toResponsiveImage(PiOn2dwG9), ...{ positionX: "center", positionY: "center" }}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Nt3nr.framer-pdfhgk, .framer-Nt3nr .framer-pdfhgk { display: block; }", ".framer-Nt3nr.framer-sjdfbs { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 180px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 250px; will-change: var(--framer-will-change-override, transform); }", ".framer-Nt3nr .framer-1mce2ck { flex: none; height: 42px; position: relative; width: 104px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Nt3nr.framer-sjdfbs { gap: 0px; } .framer-Nt3nr.framer-sjdfbs > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Nt3nr.framer-sjdfbs > :first-child { margin-left: 0px; } .framer-Nt3nr.framer-sjdfbs > :last-child { margin-right: 0px; } }", ".framer-Nt3nr.framer-v-q3exq2 .framer-1mce2ck { height: 30px; width: 74px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 180
 * @framerIntrinsicWidth 250
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"PNXJSb_ER":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"PiOn2dwG9":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framera_JFqsPzN: React.ComponentType<Props> = withCSS(Component, css, "framer-Nt3nr") as typeof Component;
export default Framera_JFqsPzN;

Framera_JFqsPzN.displayName = "Logo card small";

Framera_JFqsPzN.defaultProps = {height: 180, width: 250};

addPropertyControls(Framera_JFqsPzN, {variant: {options: ["r3GmlGDnb", "PNXJSb_ER"], optionTitles: ["Desktop", "Phone"], title: "Variant", type: ControlType.Enum}, PiOn2dwG9: {__defaultAssetReference: "data:framer/asset-reference,2e9rGrOkACVfd78cX0SzqLLw.svg?originalFilename=Logo-2.svg&preferredSize=auto", __vekterDefault: {alt: "", assetReference: "data:framer/asset-reference,2e9rGrOkACVfd78cX0SzqLLw.svg?originalFilename=Logo-2.svg&preferredSize=auto"}, title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framera_JFqsPzN, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})